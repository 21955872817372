import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreateMargin, CreatePadding } from '@theme_mixins';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';

export const useStyles = makeStyles(() => ({
    container: {
        ...CreateMargin(15, 0, 30, 0),
    },
    icon: {
        fontSize: 16,
        color: PRIMARY,
        fill: 'black',
    },
    imgList: {
        maxWidth: 50,
        maxHeight: 25,
        right: 0,
    },
}));

export const ExpanPanelStyle = {
    rounded: {
        '&:first-child': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
        '&:last-child': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
    },
    root: {
        border: `1px solid ${GRAY_PRIMARY}`,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
};

export const ExpanSummaryStyle = {
    root: {
        marginBottom: -1,
        height: 40,
        '&$expanded': {
            height: 40,
        },
        '& svg': {
            fill: 'black',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
        '& span': {
            textTransform: 'capitalize',
            fontSize: 14,
        },
    },
    expanded: {},
};

export const ExpanDetailStyle = {
    root: {
        ...CreatePadding(0, 15, 0, 15),
        '& p': {
            fontSize: 14,
        },
    },
};
