import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY } from '@theme_color';
import { CreateBorder, CreatePadding, FlexColumn } from '@theme_mixins';

export default makeStyles(() => ({
    block: {
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        ...CreatePadding(30, 0, 30, 0),
    },
    container: {
        ...FlexColumn,
        ...CreatePadding(0, 20, 0, 20),
        width: '100%',
    },
    title: {
        marginBottom: 0,
        marginLeft: 0,
    },
    checkbox: {
        paddingTop: 0,
        marginTop: 0,
    },
    checkboxContainer: {
        padding: 0,
        marginTop: 0,
    },
    select: {
        marginTop: 0,
    },
    radio: {
        marginTop: 0,
    },
    boxItem: {
        margin: '10px 0px',
        ...FlexColumn,
        '& .MuiFormControlLabel-root': {
            marginBottom: -5,
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#FF0000',
        },
        '& .MuiRadio-root.Mui-checked': {
            color: '#FF0000',
        },
    },
    radioWrapper: {
        '& .MuiRadio-root': {
            paddingLeft: 10,
        },
    },
    dropdownDefault: {
        display: 'block',
        '& .MuiFormLabel-root': {
            fontSize: 12,
            textAlign: 'left',
            fontWeight: 'bold',
        },
        '& .MuiInputLabel-shrink': {
            color: '#000000',
            transform: 'translate(0, 1.5px) scale(1)',
        },
        '& label + .MuiInput-formControl': {
            marginTop: 25,
        },
    },
    dropdownHasValue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
        '& .MuiFormLabel-root': {
            fontSize: 12,
            textAlign: 'left',
            fontWeight: 'bold',
        },
        '& .MuiInputLabel-shrink': {
            color: '#000000',
            transform: 'translate(0, 1.5px) scale(1)',
        },
        '& label + .MuiInput-formControl': {
            marginTop: 25,
        },
    },
    dropdownRemove: {
        cursor: 'pointer',
        marginTop: 15,
    },
}));
