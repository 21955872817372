import classNames from 'classnames';
import TextField from '@common_textfieldcustom';
import useStyles from '@core_modules/checkout/pages/default/components/OrderComment/style';

const OrderCommentView = (props) => {
    const styles = useStyles();
    const {
        formik,
    } = props;
    return (
        <>
            <div className={classNames(styles.rmBorder)}>
                <div className={styles.orderCommentContainer}>
                    <TextField
                        name="orderComment"
                        onChange={formik.handleChange}
                        value={formik.values.orderComment}
                        placeholder="Write Your Notes Here..."
                        className={styles.textField}
                        multiline
                        rows="6"
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        className={styles.orderComment}
                        error={!!(formik.touched.orderComment && formik.errors.orderComment)}
                        errorMessage={(formik.touched.orderComment && formik.errors.orderComment) || null}
                    />
                </div>
            </div>
        </>
    );
};

export default OrderCommentView;
