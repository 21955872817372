/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import Typography from '@common_typography';
import Radio from '@common_radio/index';
import Select from '@common_select/index';
import Checkbox from '@common_checkbox/index';
import { formatPrice } from '@helper_currency';
import Skeleton from '@material-ui/lab/Skeleton';
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from '@core_modules/checkout/pages/default/components/ExtraFee/style';
import classNames from 'classnames';

const ExtraFeeView = ({
    cart, globalCurrency, t, handleChange, selected, selectedFeeOptions, loading,
}) => {
    const styles = useStyles();
    const Loader = () => (
        <div className={styles.container}>
            <Skeleton variant="text" width="40%" height={35} />
            <Skeleton variant="text" width="80%" height={30} />
            <Skeleton variant="text" width="80%" height={30} />
        </div>
    );

    if (loading.all) {
        return <Loader />;
    }

    return (
        <div className={styles.block}>
            <Typography variant="title" type="bold" letter="uppercase">
                {t('checkout:extraService')}
            </Typography>
            <div className={styles.container} id="checkoutExtraFee">
                {cart.additional_fees.data.map((item, key) => {
                    const data = item.options.map((option) => ({
                        ...option,
                        originalLabel: option.label,
                        label: `${option.label} (${formatPrice(option.price, globalCurrency)})`,
                        value: option.option_id,
                    }));

                    const selectedValues = selectedFeeOptions(data, selected);

                    if (item.frontend_type === 'checkbox' && item.enabled) {
                        return (
                            <div className={styles.boxItem} key={key}>
                                <Typography variant="span" type="bold" className="clear-margin-padding">
                                    {item.fee_name}
                                </Typography>
                                <Checkbox
                                    label=""
                                    key={key}
                                    flex="column"
                                    data={data}
                                    value={selectedValues}
                                    classCheckbox={styles.checkbox}
                                    classContainer={styles.checkboxContainer}
                                    onChange={(val) => handleChange(item, val)}
                                    isValueOnlyFromParent
                                />
                            </div>
                        );
                    }
                    if (item.frontend_type === 'radio' && item.enabled) {
                        return (
                            <div className={classNames(styles.boxItem, styles.radioWrapper)} key={key}>
                                <Typography variant="span" type="bold" className="clear-margin-padding">
                                    {item.fee_name}
                                </Typography>
                                <Radio
                                    flex="column"
                                    valueData={data}
                                    value={selectedValues[0] ?? ''}
                                    onChange={(val) => handleChange(item, val)}
                                    classContainer={styles.radio}
                                    isCanUnselect
                                />
                            </div>
                        );
                    }
                    if (item.frontend_type === 'dropdown' && item.enabled) {
                        return (
                            <div className={styles.boxItem} key={key}>
                                <div className={selectedValues[0] ? styles.dropdownHasValue : styles.dropdownDefault}>
                                    <Select
                                        options={data}
                                        label={item.fee_name}
                                        value={selectedValues[0] ?? ''}
                                        className={styles.select}
                                        onChange={(event) => handleChange(item, event.target.value)}
                                        helperText={t('common:form:select')}
                                    />
                                    {selectedValues[0] && (
                                        <div className={styles.dropdownRemove} aria-hidden="true" onClick={() => handleChange(item, '')}>
                                            <CancelIcon color="error" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default ExtraFeeView;
