import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import useStyles from '@core_modules/commons/Countdown/style';

const Countdown = (props) => {
    const Ref = useRef(null);
    const { secCountdown, startCountdown } = props;
    const styles = useStyles();
    const [timer, setTimer] = useState('00:00');

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds,
        };
    };

    const startTimer = (e) => {
        const { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                `${minutes > 9 ? minutes : `0${minutes}`}:${
                    seconds > 9 ? seconds : `0${seconds}`}`,
            );
        }
    };

    const clearTimer = (e) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        const deadline = new Date();
        const seconds = Math.floor((secCountdown) % 60);
        const minutes = Math.floor((secCountdown / 60) % 60);
        const initTimer = `${minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`;

        setTimer(initTimer);
        deadline.setSeconds(deadline.getSeconds() + secCountdown);

        return deadline;
    };

    useEffect(() => {
        if (startCountdown) {
            clearTimer(getDeadTime());
        }
    }, [startCountdown]);

    return (
        <div className={
            classNames(
                'col-xs-12 col-md-12 col-lg-12',
                styles.countdownContainer,
                startCountdown ? styles.displayBlock : styles.displayNone,
            )
        }
        >
            <p className={
                classNames(
                    styles.pMargin,
                )
            }
            >
                Please proceed with payment in
                <span className={
                    classNames(styles.countdownText)
                }
                >
                    {timer}
                </span>
            </p>
        </div>
    );
};

export default Countdown;
