/* eslint-disable no-trailing-spaces */
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AddressFormDialog from '@plugin_addressform';
import Button from '@common_button';
import Typography from '@common_typography';
import _ from 'lodash';
import ModalAddress from '@core_modules/checkout/pages/default/components/ModalAddress';
import useStyles from '@core_modules/checkout/pages/default/components/style';

const CLOSE_ADDRESS_DIALOG = 100;

const GuestAddressView = (props) => {
    const styles = useStyles();
    const {
        data,
        checkout,
        setAddress,
        setCheckout,
        t,
        dialogProps,
        loading,
        address,
        content,
        manageCustomer,
        isOnlyVirtualProductOnCart,
        showEmptyPinpoint,
        ...other
    } = props;
    
    const gmapKey = other && other.storeConfig && other.storeConfig.icube_pinlocation_gmap_key;
    const { formik } = other;

    const [openAddress, setOpenAddress] = React.useState(false);
    return (
        <div className={styles.block} id="checkoutAddress">

            {/* MODAL ADDRESS LIST CUSTOMER */}
            <ModalAddress
                open={openAddress}
                setOpen={(status) => setOpenAddress(status)}
                t={t}
                checkout={checkout}
                setAddress={setAddress}
                setCheckout={setCheckout}
                manageCustomer={manageCustomer}
                {...other}
            />
            <div className={styles.addressContainer}>
                <div className={styles.addressText}>
                    <Typography variant="title" type="bold" letter="uppercase">
                        {isOnlyVirtualProductOnCart ? t('checkout:billingAddress') : t('checkout:shippingAddress')}
                    </Typography>

                    {/* INI SELECTED ADDRESS GUEST/CUSTOMER  */}
                    <Typography variant="p">{content}</Typography>
                </div>
                <div>

                    {/* MODAL CHANGE ADDRESS GUEST */}
                    <AddressFormDialog
                        t={t}
                        onSubmitAddress={async (dataAddress) => {
                            const { cart } = checkout.data;
                            let state = { ...checkout };

                            await setAddress(dataAddress, cart);
                            state.status.addresses = true;
                            setCheckout({
                                ...state,
                                pickup_location_code: null,
                            });

                            _.delay(() => {
                                state = { ...checkout };
                                state.status.openAddressDialog = false;
                                state.status.addresses = false;
                                setCheckout(state);
                            }, CLOSE_ADDRESS_DIALOG);
                        }}
                        loading={checkout.loading.addresses}
                        success={checkout.status.addresses}
                        open={checkout.status.openAddressDialog}
                        disableDefaultAddress
                        setOpen={() => {
                            setCheckout({
                                ...checkout,
                                status: {
                                    ...checkout.status,
                                    openAddressDialog: false,
                                },
                            });
                        }}
                        pageTitle={t('checkout:address:addTitle')}
                        {...other}
                        {...dialogProps}
                    />

                    {/* GUEST BUTTON CHANGE ADDRESS */}
                    {((!loading.address && !loading.all) && data.isGuest) && (
                        <Button
                            variant={formik.values.email !== '' && formik.values.email !== formik.values.oldEmail ? 'contained' : 'outlined'}
                            disabled={formik.values.email !== '' && formik.values.email !== formik.values.oldEmail}
                            onClick={() => {
                                setCheckout({
                                    ...checkout,
                                    status: {
                                        ...checkout.status,
                                        openAddressDialog: true,
                                    },
                                });
                            }}
                        >
                            <Typography variant="p" type="bold" letter="uppercase">
                                {/* {data.isGuest && !address
                                    ? t('common:button:addAddress')
                                    : t(_.isNull(address) ? 'common:button:manage' : 'common:button:change')} */}
                                INI GUEST WOY
                            </Typography>
                        </Button>
                    )}

                    {/* CUSTOMER BUTTON CHANGE ADDRESS */}
                    {((!loading.address && !loading.all) && !data.isGuest) && (
                        <Button
                            variant={formik.values.email !== '' && formik.values.email !== formik.values.oldEmail ? 'contained' : 'outlined'}
                            disabled={formik.values.email !== '' && formik.values.email !== formik.values.oldEmail}
                            onClick={() => setOpenAddress(true)}
                        >
                            <Typography variant="p" type="bold" letter="uppercase">
                                {/* {data.isGuest && !address
                                    ? t('common:button:addAddress')
                                    : t(_.isNull(address) ? 'common:button:manage' : 'common:button:change')} */}
                                INI CUSTOMER WOY
                            </Typography>
                        </Button>
                    )}
                </div>
            </div>
            <div className="alert-empty-pin-point">
                {
                    showEmptyPinpoint && gmapKey && (
                        <Alert style={{ fontSize: 10 }} severity="warning">
                            {t('customer:address:emptyPinPointMessage')}
                        </Alert>
                    )
                }
                {
                    checkout.error.shippingAddress && (
                        <Alert style={{ fontSize: 10 }} severity="error">
                            {t('checkout:address:invalidAddress')}
                        </Alert>
                    )
                }
            </div>

            {/* GAK TAU INI STYLE SIAPA */}
            <style jsx>
                {`
                    .alert-empty-pin-point :global(.MuiAlert-icon) {
                        font-size: 16px;
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .alert-empty-pin-point {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                `}
            </style>
        </div>
    );
};

export default GuestAddressView;
