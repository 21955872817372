/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable import/named */
import React, { useState } from 'react';
import {
    createCustomerAddress,
    updateCustomerAddress,
    updatedDefaultAddress as gqlUpdateDefaulAddress,
} from '@core_modules/checkout/services/graphql';

const ModalAddressCustomer = (props) => {
    const {
        Content, checkout, setCheckout, setAddress, open, manageCustomer, variablesAddress, selectedAddressId,
        setSelectedAddressId, addressCustomer, loading, ...other
    } = props;
    // graphql
    const [updatedDefaultAddress] = gqlUpdateDefaulAddress();
    const [updateAddress] = updateCustomerAddress();
    const [addAddress] = createCustomerAddress();
    // state
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [success] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [typeAddress, setTypeAddress] = useState('new');
    const [dataEdit, setDataEdit] = useState({});

    // handle open modal add adress button
    const handleOpenNew = (type = 'new') => {
        setOpenNew(!openNew);
        setTypeAddress(type);
    };

    // handle change selected address
    const handleChange = async (event) => {
        if (selectedAddressId !== event.target.value) {
            const state = { ...checkout };
            state.loading.addresses = true;
            state.loading.order = true;
            await setCheckout(state);
            const addressId = parseInt(event.target.value);
            setSelectedAddressId(addressId);
            let detail = {};
            for (let index = 0; index < addressCustomer.data.customer.addresses.length; index++) {
                if (addressCustomer.data.customer.addresses[index].id === addressId) {
                    detail = addressCustomer.data.customer.addresses[index];
                }
            }

            const dataAddress = await updatedDefaultAddress({
                variables: {
                    addressId,
                    street: detail.street[0],
                },
            });

            if (dataAddress && dataAddress.data && dataAddress.data.updateCustomerAddress) {
                const shipping = dataAddress.data.updateCustomerAddress;
                state.selected.address = {
                    firstname: shipping.firstname,
                    lastname: shipping.lastname,
                    city: shipping.city,
                    region: {
                        ...shipping.region,
                        label: shipping.region.region,
                    },
                    country: shipping.country,
                    postcode: shipping.postcode,
                    telephone: shipping.telephone,
                    street: shipping.street,
                };
                state.loading.addresses = false;
                state.loading.order = false;
                await setCheckout(state);
            }

            const { cart } = checkout.data;
            await setAddress(detail, cart, false);
            setLoadingAddress(false);
            return true;
        }
        let detail = {};
        const addressId = parseInt(event.target.value);
        setLoadingAddress(true);
        setSelectedAddressId(addressId);
        for (let index = 0; index < addressCustomer.data.customer.addresses.length; index++) {
            if (addressCustomer.data.customer.addresses[index].id === addressId) {
                detail = addressCustomer.data.customer.addresses[index];
            }
        }
        const { cart } = checkout.data;
        await setAddress(detail, cart, false);
        setLoadingAddress(false);
        return true;
    };

    // handle add address
    const handleAddress = async (data) => {
        setLoadingAddress(true);
        const state = { ...checkout };
        if (!success) {
            if (typeAddress === 'update') {
                await updateAddress({
                    variables: {
                        ...data,
                    },
                });
            } else {
                await addAddress({
                    variables: {
                        ...data,
                    },
                }).then(async (res) => {
                    if (res.data && res.data.createCustomerAddress && res.data.createCustomerAddress.id) {
                        const shipping = res.data.createCustomerAddress;
                        state.selected.address = {
                            firstname: shipping.firstname,
                            lastname: shipping.lastname,
                            city: shipping.city,
                            region: {
                                ...shipping.region,
                                label: shipping.region.region,
                            },
                            country: shipping.country,
                            postcode: shipping.postcode,
                            telephone: shipping.telephone,
                            street: shipping.street,
                        };
                        state.loading.addresses = false;
                        state.loading.order = false;
                        await setCheckout(state);
                    }
                });
            }
        }

        setLoadingAddress(false);

        // setOpenNew(false);
        if (openNew) {
            setOpenNew(false);
            if (manageCustomer.refetch && typeof manageCustomer.refetch() === 'function') {
                manageCustomer.refetch();
            }
        }
    };

    return (
        <Content
            loading={loading}
            addressCustomer={addressCustomer}
            selectedAddressId={selectedAddressId}
            handleChange={handleChange}
            handleOpenNew={handleOpenNew}
            handleAddress={handleAddress}
            loadingAddress={loadingAddress}
            success={success}
            openNew={openNew}
            open={open}
            typeAddress={typeAddress}
            dataEdit={dataEdit}
            setDataEdit={setDataEdit}
            manageCustomer={manageCustomer}
            updateAddress={updateAddress}
            {...other}
        />
    );
};

export default ModalAddressCustomer;
