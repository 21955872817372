import { makeStyles } from '@material-ui/core/styles';
import { GRAY_PRIMARY } from '@theme_color';
import {
    CreateBorder,
    CreatePadding,
} from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        maxHeight: 100,

        '& input': {
            paddingLeft: '14px',
            paddingRight: '14px',
        },
    },
    label: {
        textTransform: 'capitalize',
    },
    input: {
        ...CreateBorder('1px', '1px', '1px', '1px', GRAY_PRIMARY),
        ...CreatePadding(5, 5, 5, 5),
        borderRadius: '20px',
    },
}));

export default useStyles;
