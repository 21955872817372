import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import {
    CreateBorder,
    CreatePadding,
    FlexRow,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    item: {
        ...CreatePadding(15, 10, 15, 10),
        ...FlexRow,
        alignItems: 'center',
        ...CreateBorder('2px', '2px', '2px', '2px', GRAY_PRIMARY),
        borderRadius: 10,
        cursor: 'pointer',
        position: 'relative',
        '& > img': {
            display: 'none',
        },
        '& span': {
            flex: '20%',
            margin: 0,
            color: '#ADADAD',
        },
        '& span img': {
            width: '38px',
            height: 'auto',
            display: 'block',
            margin: 'auto',
            [theme.breakpoints.down('md')]: {
                width: '20px',
            },
        },
        '& .column ': {
            marginLeft: '10px',
            flex: '80%',
        },
        '& .column span:first-child': {
            fontSize: '16px',
            marginBottom: 5,
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
                marginBottom: 0,
            },
        },
        '& .column span:nth-child(2)': {
            fontSize: '12px',
        },
    },
    active: {
        ...CreateBorder('2px', '2px', '2px', '2px', PRIMARY),
        '& > img': {
            display: 'block',
            position: 'absolute',
            right: 10,
            top: 10,
            [theme.breakpoints.down('md')]: {
                width: '15px',
            },
        },
        '& span': {
            color: '#000000',
        },
    },
}));
