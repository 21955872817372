import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import classNames from 'classnames';
import useStyles from '@common_textfieldcustom/style';

const CustomTextField = ({
    placeholder = '',
    disabled = false,
    onChange = () => { },
    value = '',
    className = '',
    label = '',
    fullWidth = true,
    error = false,
    variant = 'standard',
    footer,
    styleFrameText = {},
    styleTextField = {},
    ...other
}) => {
    const styles = useStyles();
    const customClass = classNames(styles.container, className);
    return (
        <FormControl disabled={disabled} fullWidth={fullWidth} error={error} variant={variant} className={customClass} style={styleFrameText}>
            <Input
                disableUnderline
                className={styles.input}
                id={label}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={styleTextField}
                {...other}
            />
        </FormControl>
    );
};

export default CustomTextField;
