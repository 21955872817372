import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, GREEN, PRIMARY } from '@theme_color';
import { CreateMargin } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: 960,
        },
        width: ' 100%',
        alignSelf: 'center',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto',
        backgroundColor: 'white',
    },
    pageTitle: {
        marginBottom: 0,
    },
    address_shipping: {
        width: '100%',
        margin: 0,
    },
    address_radio: {
        display: 'none',
    },
    address_billing: {
        padding: '20px 15px',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
    },
    address_checklist: {
        position: 'absolute',
        top: 15,
        right: 15,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    address_title: {
        color: PRIMARY,
        fontSize: '12px',
        fontWeight: '700',
        marginBottom: '5px',
    },
    address_content: {
        fontSize: '12px',
        border: `2px solid ${GRAY_PRIMARY}`,
        padding: '15px',
        '&.active': {
            border: '2px solid black',
        },
    },
    address_text: {
        fontSize: '14px',
    },
    address_text_inactive: {
        color: '#ADADAD !important',
    },
    address_action: {
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: '14px',
        margin: 0,
        position: 'absolute',
        color: '#E51A23',
        fontFamily: 'Jamsil-Bold !important',

        [theme.breakpoints.up('sm')]: {
            bottom: 15,
            left: 15,
        },

        [theme.breakpoints.down('sm')]: {
            top: '15px',
            right: '15px',
        },
    },
    address_add: {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid black',
        fontSize: '12px',
    },
    address_save: {
        width: '100%',
        backgroundColor: PRIMARY,
        color: 'white',
        textTransform: 'uppercase',
    },
    address_drawer: {
        left: 0,
        width: '100%',
    },
    address_form: {
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: '80vh',
        },
    },
    form_input: {
        marginBottom: '25px',
    },
    addBtn: {
        ...CreateMargin(30, 0, 30, 0),
    },
    boxMap: {
        ...CreateMargin(30, 0, 60, 0),
        height: 'auto',
    },
    fontWhite: {
        color: 'white',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        textAlign: 'center',
    },
    buttonProgress: {
        color: PRIMARY,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    addBtnSuccess: {
        backgroundColor: GREEN,
        '&:hover': {
            backgroundColor: GREEN,
        },
        ...CreateMargin(30, 0, 30, 0),
    },
}));

export default useStyles;
