/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import gqlService from '@core_modules/checkout/services/graphql';
import useStyles from '@core_modules/checkout/pages/default/components/style';
import Cookies from 'js-cookie';

const DeliveryComp = (props) => {
    const {
        t,
        checkout,
        setCheckout,
        handleOpenMessage,
        storeConfig,
        DeliveryView,
        Skeleton,
        isOnlyVirtualProductOnCart,
        setSelectedAddressId,
        cartId,
        selectedVendorData,
        selectedVendorDataError,
    } = props;

    const [removePickupStore] = gqlService.removePickupStore();
    const [setPickupStore] = gqlService.setPickupStore();
    const [getVendorData, { data: dataVendor }] = gqlService.getVendorData();
    const { data: dataDisablePickupStore, loading: loadingDisablePickupStore } = gqlService.getDisablePickupStore(cartId);
    let isDisablePickupStore = false;
    if (
        !loadingDisablePickupStore
        && dataDisablePickupStore
        && dataDisablePickupStore.getDisablePickupStore
        && dataDisablePickupStore.getDisablePickupStore.disable_pickup_in_store
    ) {
        isDisablePickupStore = dataDisablePickupStore.getDisablePickupStore.disable_pickup_in_store;
    }

    const styles = useStyles();
    const handleSelect = async (delivery) => {
        await window.backdropLoader(true);
        if (delivery === 'home' && Object.keys(checkout.selectStore).length > 0 && Object.keys(checkout.pickupInformation).length > 0) {
            removePickupStore({
                variables: {
                    cart_id: checkout.data.cart.id,
                },
            })
                .then(async (res) => {
                    await setCheckout({
                        ...checkout,
                        pickup_location_code: null,
                        data: {
                            ...checkout.data,
                            cart: {
                                ...checkout.data.cart,
                                ...res.data.removePickupStore,
                            },
                        },
                        selected: {
                            ...checkout.selected,
                            delivery,
                            address: checkout.data.isGuest ? null : checkout.selected.address,
                        },
                        selectStore: {},
                        pickupInformation: {},
                    });
                    await setSelectedAddressId(null);
                    await window.backdropLoader(false);
                })
                .catch(() => {
                    handleOpenMessage({
                        variant: 'error',
                        text: t('checkout:message:problemConnection'),
                    });
                    window.backdropLoader(false);
                });
        } else if (delivery === 'pickup') {
            getVendorData({
                variables: {
                    vendor_id: parseInt(Cookies.get('selectedStore'), 10),
                },
            });
        } else if (delivery === 'instore') {
            // user chooses instore tab
            if (Object.keys(checkout.selectStore).length > 0 && Object.keys(checkout.pickupInformation).length > 0) {
                removePickupStore({
                    variables: {
                        cart_id: checkout.data.cart.id,
                    },
                })
                    .then(async (res) => {
                        await setCheckout({
                            ...checkout,
                            data: {
                                ...checkout.data,
                                cart: {
                                    ...checkout.data.cart,
                                    ...res.data.removePickupStore,
                                },
                            },
                            selected: {
                                ...checkout.selected,
                                delivery,
                                address: checkout.data.isGuest ? null : checkout.selected.address,
                            },
                            selectStore: {},
                            pickupInformation: {},
                        });
                    })
                    .catch(() => {
                        handleOpenMessage({
                            variant: 'error',
                            text: t('checkout:message:problemConnection'),
                        });
                    });
            }

            await setCheckout({
                ...checkout,
                selected: {
                    ...checkout.selected,
                    delivery,
                },
            });

            window.backdropLoader(false);
        } else if (delivery !== 'instore' && checkout.pickup_location_code) {
            // user had chosen instore tab,
            // entered some data, but later decided to switch to the other tabs
            await setCheckout({
                ...checkout,
                selected: {
                    ...checkout.selected,
                    address: null,
                    delivery,
                    selectStore: {},
                    pickupInformation: {},
                },
            });
            window.backdropLoader(false);
        } else {
            await setCheckout({
                ...checkout,
                pickup_location_code: null,
                selected: {
                    ...checkout.selected,
                    delivery,
                },
            });
            window.backdropLoader(false);
        }
    };

    useEffect(async () => {
        if (dataVendor) {
            const pickupInformation = {
                pickup_person_email: checkout.data.customer.email,
                pickup_person_name: `${checkout.data.customer.firstname} ${checkout.data.customer.lastname}`,
                pickup_person_phone: checkout.data.customer.telephone,
            };

            await setPickupStore({
                variables: {
                    cart_id: checkout.data.cart.id,
                    code: dataVendor.getVendorData.data[0].vendor_id.toString(),
                    extension_attributes: pickupInformation,
                    store_address: {
                        city: dataVendor.getVendorData.data[0].city,
                        country_code: dataVendor.getVendorData.data[0].country_id,
                        name: dataVendor.getVendorData.data[0].vendor_name,
                        postcode: dataVendor.getVendorData.data[0].zip,
                        region: dataVendor.getVendorData.data[0].region,
                        street: [dataVendor.getVendorData.data[0].street],
                        telephone: dataVendor.getVendorData.data[0].telephone,
                    },
                    device: 'desktop',
                },
            })
                .then(async (res) => {
                    const selectedShipping = checkout.data.shippingMethods.filter(({ method_code }) => method_code === 'pickup');
                    const dataLayer = {
                        event: 'checkout',
                        ecommerce: {
                            checkout: {
                                actionField: {
                                    step: 2,
                                    option: selectedShipping.length > 0 ? selectedShipping[0].label : 'Pickup at Store Pickup at Store',
                                    action: 'checkout',
                                },
                                products: checkout.data.cart.items.map(({ quantity, product, prices }) => ({
                                    name: product.name,
                                    id: product.sku,
                                    price: JSON.stringify(prices.price.value),
                                    category: product.categories.length > 0 ? product.categories[0].name : '',
                                    list: product.categories.length > 0 ? product.categories[0].name : '',
                                    quantity: JSON.stringify(quantity),
                                    dimension4: product.stock_status === 'IN_STOCK' ? 'In stock' : 'Out stock',
                                    dimension5: '',
                                    dimension6: '',
                                    dimension7: prices.discount ? 'YES' : 'NO',
                                })),
                            },
                            currencyCode: storeConfig.base_currency_code || 'IDR',
                        },
                    };
                    const dataLayerOption = {
                        event: 'checkoutOption',
                        ecommerce: {
                            currencyCode: storeConfig.base_currency_code || 'IDR',
                            checkout_option: {
                                actionField: {
                                    step: 2,
                                    option: selectedShipping.length > 0 ? selectedShipping[0].label : 'Pickup at Store Pickup at Store',
                                    action: 'checkout_option',
                                },
                            },
                        },
                    };
                    TagManager.dataLayer({
                        dataLayer,
                    });
                    TagManager.dataLayer({
                        dataLayer: dataLayerOption,
                    });

                    const paymentMethod = res.data.setPickupStore.available_payment_methods.map((method) => ({
                        ...method,
                        label: method.title,
                        value: method.code,
                        image: null,
                    }));
                    await setCheckout({
                        ...checkout,
                        data: {
                            ...checkout.data,
                            cart: {
                                ...checkout.data.cart,
                                ...res.data.setPickupStore,
                            },
                            paymentMethod,
                        },
                        selectStore: {
                            name: dataVendor.getVendorData.data[0].vendor_name,
                            ...dataVendor.getVendorData.data[0],
                        },
                        selected: {
                            ...checkout.selected,
                            delivery: 'pickup',
                            shipping: {
                                name: {
                                    carrier_code: null,
                                    method_code: null,
                                },
                                price: null,
                                original_price: null,
                            },
                        },
                        pickupInformation,
                        error: {
                            selectStore: false,
                            pickupInformation: false,
                        },
                    });
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });

            window.backdropLoader(false);
        }
    }, [dataVendor]);

    /**
     * selectedVendorData
     * untuk checking data pickup existing
     * jika berbeda dengan sebelumnya maka
     * akan panggil setPickupStore
     */
    useEffect(async () => {
        if (selectedVendorData) {
            const cartVendorName = checkout.selectStore.name;
            const currentVendorName = selectedVendorData.getVendorData.data[0].vendor_name;
            /**
             * jika method pickup dan nama store/vendor beda antara lama & current
             * maka call setPickupStore dengan current data
             * */
            if (cartVendorName !== currentVendorName) {
                const pickupInformation = {
                    pickup_person_email: checkout.data.customer.email,
                    pickup_person_name: `${checkout.data.customer.firstname} ${checkout.data.customer.lastname}`,
                    pickup_person_phone: checkout.data.customer.telephone,
                };

                await setPickupStore({
                    variables: {
                        cart_id: checkout.data.cart.id,
                        code: selectedVendorData.getVendorData.data[0].vendor_id.toString(),
                        extension_attributes: pickupInformation,
                        store_address: {
                            city: selectedVendorData.getVendorData.data[0].city,
                            country_code: selectedVendorData.getVendorData.data[0].country_id,
                            name: selectedVendorData.getVendorData.data[0].vendor_name,
                            postcode: selectedVendorData.getVendorData.data[0].zip,
                            region: selectedVendorData.getVendorData.data[0].region,
                            street: [selectedVendorData.getVendorData.data[0].street],
                            telephone: selectedVendorData.getVendorData.data[0].telephone,
                        },
                    },
                })
                    .then(async (res) => {
                        const selectedShipping = checkout.data.shippingMethods.filter(({ method_code }) => method_code === 'pickup');
                        const dataLayer = {
                            event: 'checkout',
                            ecommerce: {
                                checkout: {
                                    actionField: {
                                        step: 2,
                                        option: selectedShipping.length > 0 ? selectedShipping[0].label : 'Pickup at Store Pickup at Store',
                                        action: 'checkout',
                                    },
                                    products: checkout.data.cart.items.map(({ quantity, product, prices }) => ({
                                        name: product.name,
                                        id: product.sku,
                                        price: JSON.stringify(prices.price.value),
                                        category: product.categories.length > 0 ? product.categories[0].name : '',
                                        list: product.categories.length > 0 ? product.categories[0].name : '',
                                        quantity: JSON.stringify(quantity),
                                        dimension4: product.stock_status === 'IN_STOCK' ? 'In stock' : 'Out stock',
                                        dimension5: '',
                                        dimension6: '',
                                        dimension7: prices.discount ? 'YES' : 'NO',
                                    })),
                                },
                                currencyCode: storeConfig.base_currency_code || 'IDR',
                            },
                        };
                        const dataLayerOption = {
                            event: 'checkoutOption',
                            ecommerce: {
                                currencyCode: storeConfig.base_currency_code || 'IDR',
                                checkout_option: {
                                    actionField: {
                                        step: 2,
                                        option: selectedShipping.length > 0 ? selectedShipping[0].label : 'Pickup at Store Pickup at Store',
                                        action: 'checkout_option',
                                    },
                                },
                            },
                        };
                        TagManager.dataLayer({
                            dataLayer,
                        });
                        TagManager.dataLayer({
                            dataLayer: dataLayerOption,
                        });

                        const paymentMethod = res.data.setPickupStore.available_payment_methods.map((method) => ({
                            ...method,
                            label: method.title,
                            value: method.code,
                            image: null,
                        }));
                        await setCheckout({
                            ...checkout,
                            data: {
                                ...checkout.data,
                                cart: {
                                    ...checkout.data.cart,
                                    ...res.data.setPickupStore,
                                },
                                paymentMethod,
                            },
                            selectStore: {
                                name: selectedVendorData.getVendorData.data[0].vendor_name,
                                ...selectedVendorData.getVendorData.data[0],
                            },
                            selected: {
                                ...checkout.selected,
                                delivery: 'pickup',
                                shipping: {
                                    name: {
                                        carrier_code: null,
                                        method_code: null,
                                    },
                                    price: null,
                                    original_price: null,
                                },
                            },
                            pickupInformation,
                            error: {
                                selectStore: false,
                                pickupInformation: false,
                            },
                        });
                    })
                    .catch((err) => {
                        // eslint-disable-next-line no-console
                        console.log('error selectedVendorData', err);
                    });

                window.backdropLoader(false);
            }

            window.backdropLoader(false);
        }
    }, [selectedVendorData]);

    /**
     * selectedVendorData
     * untuk handle error dari respon BE/koneksi
     * loading dihentikan
     */
    useEffect(() => {
        if (selectedVendorDataError) {
            window.backdropLoader(false);
        }
    }, [selectedVendorDataError]);

    // to prevent user selected pickup store then update cart, but isDisablePickupStore value is true
    useEffect(() => {
        if (isDisablePickupStore && checkout.selected.delivery !== 'home' && typeof window !== 'undefined') {
            handleSelect('home');
        }
    }, [checkout.selected.delivery]);

    useEffect(() => {
        if (checkout.data.isPickupOnly) {
            handleSelect('pickup');
        }
    }, [checkout.data.isPickupOnly]);

    if (checkout.loading.all || loadingDisablePickupStore) return <Skeleton styles={styles} />;
    if (isOnlyVirtualProductOnCart) return null;
    return <DeliveryView isDisablePickupStore={isDisablePickupStore} handleSelect={handleSelect} {...props} />;
};

export default DeliveryComp;
