/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable brace-style */
/* eslint-disable radix */

import React from 'react';
import gqlService from '@core_modules/checkout/services/graphql/index';

const AdditionSelect = (props) => {
    const {
        t, checkout, setCheckout, storeConfig, ExtraFeeView,
    } = props;
    const [updateExtraFee] = gqlService.updateExtraFee();
    const {
        data: { cart },
        loading,
    } = checkout;

    const [selected, setSelected] = React.useState([]);
    const globalCurrency = storeConfig.default_display_currency_code || 'IDR';

    React.useEffect(() => {
        if (cart && cart.additional_fees && cart.applied_extra_fee) {
            const extraFee = cart.applied_extra_fee;

            // applied value
            if (!loading.extraFee) {
                if (extraFee?.select_options) {
                    setSelected(extraFee?.select_options);
                }
            }
        }
    }, [checkout]);

    const handleChange = async (fee, value) => {
        // jika loading abaikan apply
        if (loading.extraFee) {
            window.toastMessage({
                open: true,
                text: t('common:message:pleaseWait'),
                variant: 'error',
            });
            return;
        }

        const selectedBackup = selected;
        const isMultiSelect = fee.frontend_type === 'checkbox';

        const selectedParams = [];
        const selectedTemp = selected.filter((sel) => !fee.options.find((opt) => sel.option_id === opt.option_id));

        // if checkbox value is array
        if (isMultiSelect) {
            value.map((val) => {
                const findOpt = fee.options.find((opt) => opt.option_id === val);
                if (findOpt) {
                    selectedTemp.push(findOpt);
                }
            });
        }
        // if radio/dropdown value is string
        else if (value && value !== '') {
            const intValue = parseInt(value);
            const findOpt = fee.options.find((opt) => opt.option_id === intValue);
            if (findOpt) {
                selectedTemp.push(findOpt);
            }
        }

        selectedTemp.map((sel_t) => {
            selectedParams.push({
                label: sel_t.label,
                option_id: sel_t.option_id,
            });
        });

        // optimistic
        setSelected(selectedTemp);

        const isState = {
            ...checkout,
            loading: {
                ...checkout.loading,
                all: false,
                shipping: false,
                payment: false,
                extraFee: true,
                order: true,
            },
        };
        setCheckout(isState);
        updateExtraFee({
            variables: {
                cart_id: cart.id,
                select_options: selectedParams,
            },
        })
            .then(async (res) => {
                const checkoutData = { ...checkout };
                checkoutData.data.cart = {
                    ...checkoutData.data.cart,
                    ...res.data.updateExtraFeeOnCart.cart,
                    loading: {
                        ...checkout.loading,
                        all: false,
                        shipping: false,
                        payment: false,
                        extraFee: false,
                        order: false,
                    },
                };
                await setCheckout(checkoutData);
            })
            .catch((e) => {
                setSelected(selectedBackup);
                window.backdropLoader(false);
                const isStateCatch = {
                    ...checkout,
                    loading: {
                        ...checkout.loading,
                        all: false,
                        shipping: false,
                        payment: false,
                        extraFee: false,
                        order: false,
                    },
                };
                setCheckout(isStateCatch);
                window.toastMessage({
                    open: true,
                    text: e?.message ?? 'Error',
                    variant: 'error',
                });
            });
    };

    // get selected values from each fee
    const selectedFeeOptions = (feeOptions, selectedAll) => {
        const selectedOptions = [];
        const selectedFilter = selectedAll.filter((sel) => !!feeOptions.find((opt) => opt.option_id === sel.option_id));
        selectedFilter.map((sel) => selectedOptions.push(sel.option_id));
        return selectedOptions;
    };

    if (cart?.additional_fees?.data && cart?.additional_fees?.data.length > 0) {
        return (
            <ExtraFeeView
                globalCurrency={globalCurrency}
                storeConfig={storeConfig}
                t={t}
                handleChange={handleChange}
                selected={selected}
                selectedFeeOptions={selectedFeeOptions}
                loading={loading}
                cart={cart}
            />
        );
    }

    return null;
};

export default AdditionSelect;
