/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import classNames from 'classnames';
import useStyles from '@core_modules/checkout/pages/default/components/PickupInformation/style';
import useParentStyles from '@core_modules/checkout/pages/default/components/style';

const PickupInformation = (props) => {
    const {
        // eslint-disable-next-line no-unused-vars
        t, checkout, setCheckout, formik,
    } = props;
    const classes = useStyles();
    const styles = useParentStyles();

    return (
        <div className={styles.block} id="checkoutPickupStore">
            <Typography variant="title" type="bold" letter="uppercase">
                {t('checkout:pickupInformation:label')}
            </Typography>

            {((Object.keys(checkout.selectStore).length > 0) && Object.keys(checkout.pickupInformation).length > 0)
                && (
                    <div className={classNames(styles.cardPoint, classes.card)}>
                        <div className="column">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Person</td>
                                        <td>{' : '}</td>
                                        <td><strong>{checkout.pickupInformation.pickup_person_name}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{t('common:form:phoneNumber')}</td>
                                        <td>{' : '}</td>
                                        <td><strong>{checkout.pickupInformation.pickup_person_phone}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{' : '}</td>
                                        <td><strong>{checkout.pickupInformation.pickup_person_email}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            <Typography size="14" variant="span" type="bold">
                                {checkout.selectStore.name}
                            </Typography>
                            <Typography size="14">
                                {checkout.selectStore.street}
                                {' '}
                                <br />
                                {checkout.selectStore.city}
                                {' '}
                                {checkout.selectStore.region}
                                {' '}
                                {checkout.selectStore.country_id}
                                {' '}
                                {checkout.selectStore.postcode}
                                {' '}
                                {checkout.selectStore.telephone}
                            </Typography>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default PickupInformation;
