import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY } from '@theme_color';
import {
    Centering,
    CreateBorder,
    FlexRow,
} from '@theme_mixins';

export default makeStyles(() => ({
    block: {
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
    },
    fieldPoinContainer: {
        ...FlexRow,
        alignItems: 'center',
        width: '100%',
        '& > div:first-child': {
            flex: '1 1 70%',
        },
        '& > div:nth-child(2)': {
            flex: '0 0 30%',
        },
    },
    btnAplly: {
        height: 45,
        ...Centering,
        marginLeft: 5,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    rmBorder: {
        border: 'none',
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
    applyAction: {
        padding: '15px 0',
        width: '100%',

        '& button': {
            backgroundColor: '#E51A23',
            color: 'white',
            border: 'none',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '& button:hover': {
            backgroundColor: '#8a191e',
            color: 'white',
            border: 'none',
        },
        '& button span': {
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
        },
    },
}));
