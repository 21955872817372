import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, GREEN, WHITE,
} from '@theme_color';
import {
    FlexColumn,
    Centering,
} from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        background: 'white',
        width: '100%',
        height: 'auto',
        padding: 20,
        ...FlexColumn,
        position: 'sticky',
        top: 100,
        boxShadow: '0px 4px 8px #0000001A',
        borderRadius: '10px',
        '& .listSummary': {
            padding: 0,
        },
        '& > h1': {
            fontSize: 14,
        },
        '& > .row': {
            margin: 0,
        },
        '& > .row.between-xs:not(:last-child), & > .row': {
            borderBottom: `1px solid ${GRAY_PRIMARY}`,
        },
        '& .row.between-xs .col-xs-2': {
            textAlign: 'right',
        },
        '& .row.between-xs .col-xs-6': {
            padding: 0,
            '& span': {
                fontSize: 14,
            },
        },
        '& .summary-total': {
            borderTop: `1px solid ${GRAY_PRIMARY}`,
        },
    },
    summaryList: {
        padding: 0,
        '& .secondary-summary': {
            right: 0,
        },
        '& li > div:nth-child(2)': {
            right: 0,
        },
    },
    list: {
        border: 'none',
        padding: '10px 0',
    },
    footer: {
        width: '100%',
        ...FlexColumn,
        alignItems: 'center',
    },
    btnCheckout: {
        marginTop: 20,
        minWidth: '90%',
        marginBottom: 20,
    },
    paypalBtn: {
        minWidth: '90%',
    },
    labelItem: {
        maxWidth: '50%',
    },
    expanItem: {
        background: 'transparent',
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
    },

    listProduct: {
        padding: 20,
        position: 'relative',
        '& .delete': {
            position: 'absolute',
            top: 22,
            right: 0,
            fontSize: 15,
            cursor: 'pointer',
            width: 10,
            height: 10,
        },
    },
    imgProduct: {
        width: 75,
        height: 'auto',
    },
    imgBox: {
        position: 'relative',
    },
    freeItem: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        minWidth: 15,
        minHeight: 10,
        backgroundColor: GREEN,
        color: WHITE,
        fontWeight: '700',
        fontSize: 8,
        padding: 2,
        borderRadius: 3,
        ...Centering,
        marginLeft: 'auto',
        marginRight: 5,
        textTransform: 'uppercase',
    },
    bodyProductItem: {
        ...FlexColumn,
        '& > span': {
            fontSize: 14,
        },
        '& .item-minus': {
            cursor: 'pointer',
            '&::after': {
                content: '"<"',
            },
        },
        '& .item-count': {
            padding: '0px',
        },
        '& .item-plus': {
            cursor: 'pointer',
            '&::after': {
                content: '">"',
            },
        },
    },
}));

export default useStyles;
