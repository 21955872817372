/* eslint-disable no-trailing-spaces */
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Typography from '@common_typography';
import ModalAddress from '@core_modules/checkout/pages/default/components/ModalAddress';
import useStyles from '@core_modules/checkout/pages/default/components/style';
import Skeleton from '@material-ui/lab/Skeleton';

const CustomerAddressView = (props) => {
    const styles = useStyles();
    const {
        data,
        checkout,
        setAddress,
        setCheckout,
        t,
        dialogProps,
        loading,
        address,
        content,
        manageCustomer,
        addressCustomer,
        isOnlyVirtualProductOnCart,
        showEmptyPinpoint,
        ...other
    } = props;
    
    const gmapKey = other && other.storeConfig && other.storeConfig.icube_pinlocation_gmap_key;

    return (
        <div className={styles.block} id="checkoutAddress">
            <div className={styles.addressContainer}>
                <div className={styles.addressText}>
                    <Typography variant="title" type="bold" letter="uppercase">
                        {isOnlyVirtualProductOnCart ? t('checkout:billingAddress') : t('checkout:shippingAddress')}
                    </Typography>

                    {(loading.all) && <Skeleton variant="rect" width="100%" height={118} />}

                    {/* MODAL ADDRESS LIST CUSTOMER */}
                    {!(loading.all) && (
                        <ModalAddress
                            t={t}
                            checkout={checkout}
                            setAddress={setAddress}
                            setCheckout={setCheckout}
                            manageCustomer={manageCustomer}
                            addressCustomer={addressCustomer}
                            loading={loading}
                            {...other}
                        />
                    )}

                </div>
            </div>
            <div className="alert-empty-pin-point">
                {
                    showEmptyPinpoint && gmapKey && (
                        <Alert style={{ fontSize: 10 }} severity="warning">
                            {t('customer:address:emptyPinPointMessage')}
                        </Alert>
                    )
                }
                {
                    checkout.error.shippingAddress && (
                        <Alert style={{ fontSize: 10 }} severity="error">
                            {t('checkout:address:invalidAddress')}
                        </Alert>
                    )
                }
            </div>

            {/* GAK TAU INI STYLE SIAPA */}
            <style jsx>
                {`
                    .alert-empty-pin-point :global(.MuiAlert-icon) {
                        font-size: 16px;
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .alert-empty-pin-point {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                `}
            </style>
        </div>
    );
};

export default CustomerAddressView;
