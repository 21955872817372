import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    card: {
        marginBottom: 15,
        marginLeft: 0,
        marginRight: 0,
        '& strong': {
            fontWeight: '500',
        },
        '& table': {
            marginBottom: 15,
        },
        '& table td': {
            fontFamily: 'Jamsil-Regular',
            fontSize: 14,
        },
    },
}));
