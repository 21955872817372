import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@common_typography';
import React from 'react';
import AddressFormDialog from '@plugin_addressform';
import useStyles from '@core_modules/checkout/pages/default/components/ItemModalAddress/style';
import classNames from 'classnames';

const ItemAddress = (props) => {
    const {
        firstname = '',
        lastname = '',
        street = '',
        postcode = '',
        country = '',
        region = '',
        city = '',
        telephone = '',
        value = '',
        checked = false,
        loading,
        open,
        setOpen,
        handleSave,
        success,
        t,
        // eslint-disable-next-line no-unused-vars
    } = props;
    const styles = useStyles();
    return (
        <>
            <AddressFormDialog
                {...props}
                open={open}
                onSubmitAddress={handleSave}
                loading={loading}
                success={success}
                setOpen={() => setOpen(false)}
                pageTitle={t('customer:address:editTitle')}
            />
            <div className={styles.addressColumn} id="checkoutListItemAddress">
                <div className={classNames(styles.address_content, checked && 'active')}>
                    <FormControlLabel
                        className={classNames(styles.address_shipping)}
                        value={value}
                        checked={checked}
                        control={<Radio color="primary" size="small" className={styles.address_radio} />}
                        label={(
                            <>
                                <Typography className={classNames(styles.address_text, !checked && styles.address_text_inactive)} variant="p">
                                    {`${firstname} ${lastname}`}
                                </Typography>
                                <Typography className={classNames(styles.address_text, !checked && styles.address_text_inactive)} variant="p">
                                    {`${street} , `}
                                    {city !== '' && `${city}, `}
                                    {region !== '' && `${region}, `}
                                    {country !== '' && `${country.full_name_locale || ''}, `}
                                    {postcode !== '' && `${postcode} `}
                                    {telephone}
                                </Typography>
                                {
                                    !checked && (
                                        <Typography className={classNames(styles.address_action, styles.address_text_inactive)} variant="span">
                                            {t('customer:address:changeShippingAddress')}
                                        </Typography>
                                    )
                                }
                            </>
                        )}
                        labelPlacement="end"
                    />
                    {
                        checked && (
                            <Typography className={classNames(styles.address_action)} variant="span" onClick={() => setOpen(true)}>
                                {t('customer:address:editTitle')}
                            </Typography>
                        )
                    }
                    {checked && <img src="/assets/svg/checklist.svg" alt="selected" className={styles.address_checklist} />}
                </div>
            </div>
        </>
    );
};

export default ItemAddress;
