import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    countdownContainer: {
        position: 'fixed',
        backgroundColor: '#FFFFFF',
        zIndex: '9999999',
        left: 0,
        top: 0,
        width: '100%',
        textAlign: 'center',
        border: '4px solid #C30031',
    },
    pMargin: {
        margin: '.22rem 0 .22rem 0',
    },
    displayBlock: {
        display: 'block',
    },
    displayNone: {
        display: 'none',
    },
    countdownText: {
        fontWeight: '700',
        color: '#C30031',
    },
}));
