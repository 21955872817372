/* eslint-disable no-unused-expressions */
import React from 'react';
import Button from '@common_button';
import Typography from '@common_typography';
import classNames from 'classnames';
import Delivery from '@core_modules/checkout/pages/default/components/delivery';
import Email from '@core_modules/checkout/pages/default/components/email';
import Summary from '@core_modules/checkout/pages/default/components/summary';
import Address from '@core_modules/checkout/pages/default/components/address';
import Shipping from '@core_modules/checkout/pages/default/components/shipping';
import PaymentList from '@core_modules/checkout/pages/default/components/payment';
import Promo from '@core_modules/checkout/pages/default/components/promo';
import GiftCard from '@core_modules/checkout/pages/default/components/giftcard';
import OrderComment from '@core_modules/checkout/pages/default/components/OrderComment';
import RewardPoint from '@core_modules/checkout/pages/default/components/rewardpoint';
import Credit from '@core_modules/checkout/pages/default/components/credit';
import PickupInfo from '@core_modules/checkout/pages/default/components/PickupInformation';
import ExtraFee from '@core_modules/checkout/pages/default/components/ExtraFee/index';
import PromoModalItem from '@core_modules/checkout/pages/default/components/PromoModalItem';
import useStyles from '@core_modules/checkout/pages/default/components/style';
import InStorePickup from '@core_modules/checkout/pages/default/components/instorepickup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Content = (props) => {
    const {
        containerStyle,
        checkout,
        storeConfig,
        CashbackInfoView,
        chasbackMessage,
        DeliveryView,
        DeliverySkeleton,
        formik,
        t,
        setCheckout,
        isOnlyVirtualProductOnCart,
        handleOpenMessage,
        EmailView,
        config,
        updateFormik,
        CustomerAddressView,
        GuestAddressView,
        ShippingView,
        PaymentView,
        PromoView,
        GiftCardView,
        OrderCommentView,
        SummaryView,
        RewardPointView,
        StoreCreditView,
        modules,
        HeaderView,
        manageCustomer,
        ExtraFeeView,
        cartId,
        PromoModalItemView,
        paypalTokenData,
        paypalHandlingProps,
        setInitialOptionPaypal,
        initialOptionPaypal,
        setTokenData,
        variables,
        selectedAddressId,
        setSelectedAddressId,
        addressCustomer,
        selectedVendorData,
        selectedVendorDataError,
        isRequiredTerms,
        setIsRequiredTerms,
    } = props;

    const styles = useStyles();
    const SummaryRef = React.createRef();
    const isRequiredTermsRef = React.useRef(null);
    const { order: loading, all: disabled } = checkout.loading;
    const isSelectedPurchaseOrder = checkout.selected.payment === 'purchaseorder';
    // prettier-ignore
    const isPurchaseOrderApply = isSelectedPurchaseOrder && checkout.status.purchaseOrderApply;
    const isHomeDelivery = checkout.selected.delivery === 'home';

    /**
     * [METHOD] handle click for place order
     */
    const handleClick = () => {
        if (SummaryRef.current) {
            SummaryRef.current.handlePlaceOrder();
        }
    };

    // for isRequiredTerms
    const scrollToRequiredTerm = () => {
        isRequiredTermsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    /**
     * [VIEW]
     */

    return (
        <div id="checkout" className={classNames(styles.mobileBottomSpace, styles.checkoutContainer, 'row between-lg')}>
            <div className="col-xs-12 center hidden-mobile">
                <HeaderView storeConfig={storeConfig} />
            </div>
            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-7 checkout-form" style={containerStyle || {}}>
                {modules.checkout.cashback.enabled && checkout.data.cart && checkout.data.cart.applied_cashback.is_cashback && (
                    <CashbackInfoView
                        message={chasbackMessage}
                        price={checkout.data.cart.applied_cashback.data[0].amount}
                        currency={storeConfig.base_currency_code}
                        promo_name={checkout.data.cart.applied_cashback.data[0].promo_name}
                    />
                )}
                <>
                    <Email
                        t={t}
                        formik={formik}
                        EmailView={EmailView}
                        checkout={checkout}
                        config={config}
                        setCheckout={setCheckout}
                        handleOpenMessage={handleOpenMessage}
                        cartId={cartId}
                    />
                    {modules.checkout.pickupStore.enabled || modules.checkout.inStorePickup.enabled ? (
                        <Delivery
                            t={t}
                            DeliveryView={DeliveryView}
                            Skeleton={DeliverySkeleton}
                            formik={formik}
                            checkout={checkout}
                            setCheckout={setCheckout}
                            handleOpenMessage={handleOpenMessage}
                            storeConfig={storeConfig}
                            isOnlyVirtualProductOnCart={isOnlyVirtualProductOnCart}
                            selectedAddressId={selectedAddressId}
                            setSelectedAddressId={setSelectedAddressId}
                            cartId={cartId}
                            selectedVendorData={selectedVendorData}
                            selectedVendorDataError={selectedVendorDataError}
                        />
                    ) : null}
                    {/* eslint-disable */}
                    {checkout.selected.delivery === 'home' ? (
                        <Address
                            checkout={checkout}
                            t={t}
                            setCheckout={setCheckout}
                            defaultAddress={checkout.data.defaultAddress}
                            updateFormik={updateFormik}
                            CustomerAddressView={CustomerAddressView}
                            GuestAddressView={GuestAddressView}
                            manageCustomer={manageCustomer}
                            storeConfig={storeConfig}
                            formik={formik}
                            isOnlyVirtualProductOnCart={isOnlyVirtualProductOnCart}
                            variablesAddress={variables}
                            selectedAddressId={selectedAddressId}
                            addressCustomer={addressCustomer}
                            setSelectedAddressId={setSelectedAddressId}
                        />
                    ) : checkout.selected.delivery === 'pickup' ? (
                        <PickupInfo t={t} formik={formik} checkout={checkout} setCheckout={setCheckout} />
                    ) : (
                        <InStorePickup handleOpenMessage={handleOpenMessage} t={t} checkout={checkout} setCheckout={setCheckout} />
                    )}
                    {checkout.selected.delivery === 'home' && (
                        <Shipping
                            t={t}
                            checkout={checkout}
                            setCheckout={setCheckout}
                            updateFormik={updateFormik}
                            formik={formik}
                            handleOpenMessage={handleOpenMessage}
                            storeConfig={storeConfig}
                            ShippingView={ShippingView}
                            isOnlyVirtualProductOnCart={isOnlyVirtualProductOnCart}
                        />
                    )}

                    <div className={classNames(styles.block)}>
                        <Typography variant="title" type="bold" letter="uppercase">
                            {t('checkout:feePromoLabel')}
                        </Typography>
                        <div className="row">
                            {modules.giftcard.enabled ? (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                                    <GiftCard
                                        t={t}
                                        checkout={checkout}
                                        setCheckout={setCheckout}
                                        handleOpenMessage={handleOpenMessage}
                                        formik={formik}
                                        storeConfig={storeConfig}
                                        GiftCardView={GiftCardView}
                                    />
                                </div>
                            ) : null}
                            {modules.rewardpoint.enabled ? (
                                <div className="col-xs-12 col-sm-12 col-md-6 col-xl-6">
                                    <RewardPoint
                                        t={t}
                                        checkout={checkout}
                                        setCheckout={setCheckout}
                                        handleOpenMessage={handleOpenMessage}
                                        formik={formik}
                                        storeConfig={storeConfig}
                                        RewardPointView={RewardPointView}
                                    />
                                </div>
                            ) : null}
                            {modules.storecredit.enabled ? (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                                    <Credit
                                        t={t}
                                        checkout={checkout}
                                        setCheckout={setCheckout}
                                        handleOpenMessage={handleOpenMessage}
                                        formik={formik}
                                        storeConfig={storeConfig}
                                        StoreCreditView={StoreCreditView}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <PaymentList
                        checkout={checkout}
                        setCheckout={setCheckout}
                        updateFormik={updateFormik}
                        handleOpenMessage={handleOpenMessage}
                        t={t}
                        storeConfig={storeConfig}
                        PaymentView={PaymentView}
                        modules={modules}
                        paypalTokenData={paypalTokenData}
                        paypalHandlingProps={paypalHandlingProps}
                        setInitialOptionPaypal={setInitialOptionPaypal}
                        initialOptionPaypal={initialOptionPaypal}
                        setTokenData={setTokenData}
                    />

                    {modules.checkout.extraFee.enabled ? (
                        <ExtraFee
                            checkout={checkout}
                            setCheckout={setCheckout}
                            updateFormik={updateFormik}
                            handleOpenMessage={handleOpenMessage}
                            t={t}
                            storeConfig={storeConfig}
                            ExtraFeeView={ExtraFeeView}
                        />
                    ) : null}

                    {modules.promo.enabled ? (
                        <div className={classNames(styles.block)}>
                            <Typography variant="title" type="bold" letter="uppercase">
                                {t('checkout:promoCodePlaceholder')}
                            </Typography>
                            <Promo
                                t={t}
                                checkout={checkout}
                                setCheckout={setCheckout}
                                handleOpenMessage={handleOpenMessage}
                                formik={formik}
                                storeConfig={storeConfig}
                                PromoView={PromoView}
                            />
                        </div>
                    ) : null}

                    {modules.checkout.orderComment.enabled ? (
                        <div className={styles.orderCommentBlock}>
                            <Typography variant="title" type="bold" letter="uppercase">
                                Notes
                            </Typography>
                            <OrderComment
                                t={t}
                                checkout={checkout}
                                setCheckout={setCheckout}
                                handleOpenMessage={handleOpenMessage}
                                formik={formik}
                                storeConfig={storeConfig}
                                OrderCommentView={OrderCommentView}
                            />
                        </div>
                    ) : null}

                    {modules.checkout.isRequiredTerms ? (
                        <div className={styles.isRequiredTerms} ref={isRequiredTermsRef}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isRequiredTerms"
                                        color="primary"
                                        checked={isRequiredTerms}
                                        onChange={() => {
                                            setIsRequiredTerms(!isRequiredTerms);
                                        }}
                                    />
                                }
                                label={t('checkout:checkoutTermLabel')}
                            />
                            {!isRequiredTerms ? (
                                <div className={styles.isRequiredTermsWarning}>
                                    <span>{t('checkout:checkoutTermWarning')}</span>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 checkout-summary">
                <PromoModalItem
                    t={t}
                    storeConfig={storeConfig}
                    checkout={checkout}
                    setCheckout={setCheckout}
                    PromoModalItemView={PromoModalItemView}
                />
                <Summary
                    {...props}
                    loading={loading}
                    disabled={disabled}
                    checkout={checkout}
                    updateFormik={updateFormik}
                    setCheckout={setCheckout}
                    handleOpenMessage={handleOpenMessage}
                    formik={formik}
                    storeConfig={storeConfig}
                    SummaryView={SummaryView}
                    // eslint-disable-next-line no-return-assign
                    refSummary={SummaryRef}
                    isOnlyVirtualProductOnCart={isOnlyVirtualProductOnCart}
                    scrollToRequiredTerm={scrollToRequiredTerm}
                />
            </div>
            <div className="col-xs-12 col-sm-7 hidden-mobile center">
                <div className={[styles.placeAction].join(' ')}>
                    <Button
                        customRootStyle={{ marginBottom: 80, marginTop: 50 }}
                        onClick={handleClick}
                        fullWidth
                        loading={loading}
                        disabled={
                            disabled ||
                            checkout.error.shippingAddress ||
                            (isSelectedPurchaseOrder && !isPurchaseOrderApply) ||
                            (isHomeDelivery && !selectedAddressId) ||
                            (modules.checkout.isRequiredTerms && !isRequiredTerms)
                        }
                        className={styles.placeOrderDesktop}
                    >
                        <Typography variant="span" letter="uppercase" type="bold" color="white">
                            {t('checkout:placeOrder')}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Content;
