import { CreatePadding } from '@theme_mixins';
import { GRAY_PRIMARY } from '@theme_color';

const configStyleIcon = {
    width: 30,
    height: 30,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'center',
    marginRight: 10,
};

export const IconAccordion = {
    reguler: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-reguler.svg)',
    },
    freeshipping: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-free.svg)',
    },
    express: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-express.svg)',
    },
    instant: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-instant.svg)',
    },
    sameday: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-sameday.svg)',
    },
    international: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-international.svg)',
    },
    trucking: {
        ...configStyleIcon,
        backgroundImage: 'url(/assets/img/shipping-trucking.svg)',
    },
};

export const ExpanPanelStyle = {
    rounded: {
        '&:first-child': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
        '&:last-child': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
    },
    root: {
        border: `1px solid ${GRAY_PRIMARY}`,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        // '&:last-child > div': {
        //     borderBottom: 'none',
        // }
    },
    expanded: {},
};

export const ExpanSummaryStyle = {
    root: {
        marginBottom: -1,
        height: 40,
        '&$expanded': {
            height: 40,
        },
        '& svg': {
            fill: 'black',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
};

export const ExpanDetailStyle = {
    root: {
        width: '100% !important',
        ...CreatePadding(0, 15, 0, 15),

        '& p': {
            fontSize: 14,
        },
    },
};
