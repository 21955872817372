import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import {
    Centering, CreateBorder, CreatePadding, FlexColumn, FlexRow, CreateMargin,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    root: {
        ...FlexColumn,
        height: '100vh',
        width: '100%',
    },
    container: {
        width: '100%',
        height: 'auto',
        paddingBottom: 150,
    },
    block: {
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        ...CreatePadding(30, 0, 30, 0),
    },
    addressContainer: {
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    addressText: {
        ...FlexColumn,
    },
    listShipping: {
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    listShippingGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        boxSizing: 'inherit',
    },
    radioShiping: {
        width: '100% !important',
    },
    btnPoint: {
        maxWidth: 140,
        ...Centering,
        padding: 5,
    },
    cardPoint: {
        ...CreateMargin(5, 0, 5, 0),
        ...CreatePadding(17, 17, 17, 17),
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...CreateBorder('1px', '1px', '1px', '1px', GRAY_PRIMARY),
        borderRadius: 10,
        '& button': {
            backgroundColor: '#E51A23',
            color: 'white',
            border: 'none',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '& button:hover': {
            backgroundColor: '#8a191e',
            color: 'white',
            border: 'none',
        },
        '& button span, & button p': {
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
        },
    },
    pointText: {
        fontSize: 20,
        ...CreateMargin(0, 0, 0, 5),
    },
    pointTitle: {
        fontSize: 14,
        fontFamily: 'Jamsil-Bold !important',
    },
    btnBalanceGift: {
        ...CreateMargin(-25, 0, 0, -5),
    },
    rmBorder: {
        border: 'none',
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
    mediumCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    largeCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -15,
        marginLeft: -15,
    },
    customFormControl: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    // LOTTE MART EMAIL SECTION START HERE
    emailContainer: {
        margin: '0',
    },
    emailBox: {
        border: '1px solid #ADADAD',
        borderRadius: '20px',
        paddingRight: 0,
        '& input': {
            paddingTop: 10,
            paddingBottom: 10,
        },
        '& fieldset': {
            border: 'none !important',
        },
    },
    emailLogin: {
        margin: 0,
        fontFamily: 'Jamsil-Regular',
        '& span': {
            borderBottom: '1px solid #000000',
            cursor: 'pointer',
        },
    },
    emailQuestionsIcon: {
        right: '10px',
        padding: '0px',
    },
    giftCardContainer: {
        position: 'relative',
    },
    giftcardInfoContainer: {
        ...CreateMargin(-30, 10, 30, 5),
    },
    giftCard: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    paymentExpansionContainer: {
        marginTop: '10px',
    },
    placeOrderDesktop: {
        maxWidth: 500,
        height: 50,
    },
    labelAccordion: {
        ...FlexRow,
        alignItems: 'center',
        '& span': {
            fontSize: 14,
        },
    },
    mobileBottomSpace: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 140,
        },
    },
    paymentHeader: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paymentNoticeMesssage: {
        fontSize: 14,
        marginTop: 0,
        marginBottom: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        },
    },
    howToPay: {
        ...CreatePadding(5, 15, 5, 15),
    },
    // LOTTE MART STYLE HERE
    checkoutContainer: {
        maxWidth: '1200px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            '& .checkout-form': {
                padding: '0 15px 200px 15px',
            },
        },
    },
    deliveryTitle: {
        marginBottom: '10px',
    },
    deliveryNoticeMessage: {
        fontSize: '14px !important',
        marginBottom: '15px',
        textTransform: 'capitalize',
        fontFamily: 'Jamsil-Regular',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px !important',
        },
    },
    orderCommentBlock: {
        padding: '15px 0',
    },
    placeAction: {
        padding: '15px 0',

        '& button': {
            backgroundColor: '#E51A23',
            color: 'white',
            border: 'none',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '& button:hover': {
            backgroundColor: '#8a191e',
            color: 'white',
            border: 'none',
        },
        '& button span': {
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
        },
    },
    isRequiredTerms: {
        marginTop: 50,
        // margin: '10px 0px',
        // ...FlexColumn,
        '& .MuiFormControlLabel-root': {
            marginBottom: -5,
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#FF0000',
        },
        '& .MuiRadio-root.Mui-checked': {
            color: '#FF0000',
        },
        '& .MuiFormControlLabel-label': {
            color: '#000000',
            fontSize: 14,
        },
    },
    isRequiredTermsWarning: {
        color: '#FF0000',
        marginTop: 10,
    },
}));
