/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import classNames from 'classnames';
import useStyles from '@core_modules/checkout/pages/default/components/delivery/style';
import useStylesRoot from '@core_modules/checkout/pages/default/components/style';
import { modules } from '@config';

const ShippingView = (props) => {
    const {
        checkout, handleSelect, t, isDisablePickupStore,
    } = props;
    const classes = useStyles();
    const styles = useStylesRoot();
    const checkStyles = (delivery) => ((checkout.selected.delivery === delivery)
        ? classNames(classes.item, classes.active, `${delivery}Delivery`)
        : classNames(classes.item, `${delivery}Delivery`));

    return (
        <div id="checkoutDeliveryMethod" className={styles.block}>
            <Typography variant="title" type="bold" letter="uppercase" className={styles.deliveryTitle}>
                {t('checkout:deliveryMethod:label')}
            </Typography>
            <Typography variant="p" letter="capitalize" className={styles.deliveryNoticeMessage}>
                Please select your delivery method
            </Typography>
            <div className="row">
                {
                    (!checkout.data.isPickupOnly) ? (
                        <div className="col-xs-6">
                            <div className={checkStyles('home')} onClick={() => handleSelect('home')}>
                                <span>
                                    <img src="/assets/svg/home_delivery.svg" alt="Home Delivery" />
                                </span>
                                <div className="column">
                                    <Typography variant="span" type="bold">
                                        {t('checkout:deliveryMethod:homeDelivery')}
                                    </Typography>
                                    <Typography className="hidden-mobile">
                                        {t('checkout:deliveryMethod:homeDeliveryDesc')}
                                    </Typography>
                                </div>
                                <img src="/assets/svg/checklist.svg" alt="selected" />
                            </div>
                        </div>
                    ) : null
                }
                {
                    (modules.checkout.pickupStore.enabled && !isDisablePickupStore)
                        ? (
                            <div className="col-xs-6">
                                <div className={checkStyles('pickup')} onClick={() => handleSelect('pickup')}>
                                    <span>
                                        <img src="/assets/svg/pickup_delivery.svg" alt="Pickup Delivery" />
                                    </span>
                                    <div className="column">
                                        <Typography variant="span" type="bold">
                                            {t('checkout:deliveryMethod:pickupDelivery')}
                                        </Typography>
                                        <Typography className="hidden-mobile">
                                            {t('checkout:deliveryMethod:pickupDeliveryDesc')}
                                        </Typography>
                                    </div>
                                    <img src="/assets/svg/checklist.svg" alt="selected" />
                                </div>
                            </div>
                        ) : null
                }
                {modules.checkout.inStorePickup.enabled && !checkout.data.isPickupOnly && (
                    <div className="col-xs-6">
                        <div className={checkStyles('instore')} onClick={() => handleSelect('instore')}>
                            <div className="column">
                                <Typography variant="span" type="bold">
                                    {t('checkout:deliveryMethod:instorePickup')}
                                </Typography>
                                <Typography className="hidden-mobile">
                                    {t('checkout:deliveryMethod:instorePickupDesc')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShippingView;
